import React from 'react';
import PropTypes from 'prop-types';
import { parseFaqsList } from 'common/transformers';
import { graphql } from 'gatsby';
import Link from 'atomic-design/atoms/link';
import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import Image from 'core/image';
import Chip from 'atomic-design/atoms/chip';
import RichTextWrapper from 'atomic-design/organisms/rich-text-wrapper';
import HeroImage from 'core/image/hero-image';
import NavigationCards from 'atomic-design/molecules/navigation-cards';
import Author from 'atomic-design/molecules/author';
import Share from 'atomic-design/molecules/share';
import { addProtocol, getCategoryColor } from 'common/helpers';
import { HeaderText, NavigationTitle, Diagonal, ShareAuthorText } from './blog/styles';
import richTextOptions from 'common/rich-text-options';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';
import MissingDataPage from '../components/atomic-design/atoms/missing-data-page';
import { validateBlogPost } from '../common/validations';
import FaqsTag from '../components/core/faqs-tags';
import FaqSection from 'atomic-design/organisms/faq-section';

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      htmlTitleTag
      subtitle
      slug
      excerpt {
        excerpt
      }
      publishedDate
      hero {
        contentful_id
        title
        file {
          url
        }
      }
      technologies {
        name
        url
        image {
          file {
            url
          }
          contentful_id
        }
      }
      author {
        fullName
        thumbnail {
          contentful_id
        }
      }
      category {
        name
        slug
      }
      body {
        json
      }
      faQs {
        title
        items {
          answer {
            json
          }
          question
        }
      }
    }
  }
`;

const Post = ({ pageContext, data, location }) => {
  const { slug, previous, next } = pageContext;
  const post = validateBlogPost(data.contentfulBlogPost);
  const faqList = post.faQs && parseFaqsList(post.faQs.items);

  return (
    <Layout bodyType="xWide" path={location.pathname} withReadingProgress>
      {post.error ? (
        <MissingDataPage error={post.error}></MissingDataPage>
      ) : (
        <>
          <MetaTags
            htmlTitleTag={post.htmlTitleTag || post.title}
            title={post.title}
            charSet="utf-8"
            description={post.excerpt.excerpt}
            image={addProtocol(post.hero?.file.url)}
            type="website"
            viewport="width=device-width, initial-scale=1"
          />
          {post.faQs && <FaqsTag list={faqList} />}
          <PageSection containerSize="s">
            <HeaderText>
              <Chip color={getCategoryColor(post.category.slug)}>
                <Link to={`/blog/category/${post.category.slug}`} rel={post.category.slug}>
                  {post.category.name}
                </Link>
              </Chip>

              <h1> {post.title} </h1>
              <p> {post.subtitle}</p>

              <ShareAuthorText>
                <Share />
                {post.author && (
                  <Author
                    imageID={post.author.thumbnail.contentful_id}
                    name={post.author.fullName}
                    date={post.publishedDate}
                  />
                )}
              </ShareAuthorText>
            </HeaderText>
          </PageSection>
          <HeroImage>
            <Image
              alt={post.hero?.title}
              contentful_id={post.hero?.contentful_id}
              fromContentful
              desktopHeight="408px"
              tabletHeight="316px"
              mobileHeight="255px"
              borderRadius="15px"
            />
          </HeroImage>
          <PageSection containerSize="s">
            <RichTextWrapper
              color={getCategoryColor(post.category.slug)}
              json={{
                data: post.body.json.data,
                content: post.body.json.content,
                nodeType: post.body.json.nodeType,
              }}
              options={richTextOptions}
            />
          </PageSection>
          {post.faQs && (
            <PageSection containerSize="s">
              <FaqSection faqList={faqList} title={post.faQs.title} />
            </PageSection>
          )}
        </>
      )}

      <PageSection
        background={`${theme.newTheme.color.primary.light}20`}
        margin={`${theme.newTheme.spacing(25)} 0 0 0`}
        padding={`0 0 ${theme.newTheme.spacing(25)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(1)} 0`}
      >
        <Diagonal />
        <NavigationTitle>More articles to read</NavigationTitle>
        <NavigationCards
          previous={previous.node}
          previousTo={`/blog/${previous.node.slug}`}
          next={next ? next.node : undefined}
          nextTo={`/blog/${next.node.slug}`}
          from="Post"
          withArrow
        />
      </PageSection>

      <PageSection
        background={`${theme.newTheme.color.primary.light}20`}
        padding={`${theme.newTheme.spacing(5)} 0`}
      >
      </PageSection>
    </Layout>
  );
};

Post.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      category: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      excerpt: PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
      }),
      hero: PropTypes.shape({
        title: PropTypes.string.isRequired,
        contentful_id: PropTypes.string.isRequired,
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
      author: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        thumbnail: PropTypes.shape({
          contentful_id: PropTypes.string.isRequired,
        }),
      }),
      publishedDate: PropTypes.string.isRequired,
      body: PropTypes.shape({
        json: PropTypes.instanceOf(Object),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.node,
    previous: PropTypes.node,
  }).isRequired,
  previous: PropTypes.shape({
    node: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
  next: PropTypes.shape({
    node: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
};

Post.defaultProps = {
  previous: undefined,
  next: undefined,
};

export default Post;
