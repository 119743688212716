import React from 'react';

import { FacebookIcon, LinkedInIcon, TwitterIcon } from 'ui/svg';
import { getCurrentPath } from 'common/helpers';
import { IconLink, ShareStyled } from './styles';

export default () => (
  <ShareStyled>
    <IconLink
      href={`https://twitter.com/intent/tweet?text=${getCurrentPath()}`}
      target="_blank"
      rel="noreferrer"
    >
      <TwitterIcon />
    </IconLink>
    <IconLink
      href={`https://www.facebook.com/sharer/sharer.php?u=${getCurrentPath()}`}
      target="_blank"
      rel="noreferrer"
    >
      <FacebookIcon />
    </IconLink>
    <IconLink
      href={`https://www.linkedin.com/sharing/share-offsite/?url=${getCurrentPath()}`}
      target="_blank"
      rel="noreferrer"
    >
      <LinkedInIcon />
    </IconLink>
    <p>Sharing is caring!</p>
  </ShareStyled>
);
