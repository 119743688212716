import React from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';
import { formatDate } from 'common/helpers';
import { AuthorContainer, InfoContainer, AuthorName, Date } from './styles';

const Author = ({ imageID, name, date }) => (
  <AuthorContainer>
    <Image
      fromContentful
      contentful_id={imageID}
      fixed
      height="54px"
      width="54px"
      alt={name}
      borderRadius="50%"
    />
    <InfoContainer>
      <AuthorName>{`by ${name}`}</AuthorName>
      <Date>{formatDate(date)}</Date>
    </InfoContainer>
  </AuthorContainer>
);

Author.propTypes = {
  imageID: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
};

Author.defaultProps = {
  date: '',
};

export default Author;
