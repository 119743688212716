import styled from 'styled-components';
import Link from 'atomic-design/atoms/link';

export const ShareStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 52px 0px 9px 0px;
  p {
    font-family: ${({ theme }) => theme.fonts.family.primary};
    font-size: 16px;
    line-height: 26px;
    color: #979797;
  }
  @media only screen and (${props => props.theme.breakpoints.mobile}) {
    margin: 39px 0px 26px 0px;
  }
`;

export const IconLink = styled(Link)`
  margin-right: 10px;
  @media only screen and (${props => props.theme.breakpoints.mobile}) {
    .icon {
      zoom: 0.8;
    }
  }
`;
