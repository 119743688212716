import styled from 'styled-components';

export const AuthorContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 36px 0;

  div:first-child {
    max-height: 54px;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 9px;
  height: inherit;
  justify-content: center;
`;

export const AuthorName = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: ${props => props.theme.colors.black};
  padding: 0;
  margin: 0;
`;

export const Date = styled(AuthorName)`
  color: #bdbdbd;
`;
